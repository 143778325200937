<template>
  <v-app v-bind:style="backgroundDefault">
    <transition name="fade">
      <v-main class="ma-0 pa-0">
        <router-view />
      </v-main>
    </transition>

    <v-footer color="primary lighten-1" padless>
      <v-row justify="center" no-gutters>
        <template>
          <v-btn
            v-for="link in links.filter((_) => _.footer == true)"
            :key="link.linkId"
            color="white"
            text
            v-bind:style="{ 'font-family': $appConfig.fontName }"
            @click="openFooter(link.linkId)"
          >
            {{ link.linkName.find((_) => _.culture === $i18n.locale).name }}
          </v-btn>
        </template>
        <v-dialog v-model="dialog" width="500" scrollable>
          <v-card>
            <v-card-title>
              {{ currentFooter.linkName }}
            </v-card-title>
            <v-card-text>
              <div class="ql-editor" v-html="currentFooter.linkContent"></div>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                {{ $t("common.close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-col
          class="d-flex flex-row primary lighten-2 pa-0 ma-0 text-center white--text"
          cols="12"
        >
          <v-row class="pa-0 ma-0" justify="center" align="end">
            <p class="pa-2 ma-0">
               {{this.$appConfig.copyright.find(
                    (_) => _.culture === $i18n.locale
                  ).value }}
            </p>
          </v-row>

          <!--  <v-row class="pa-0 ma-0" justify="end" align-content="center">
            <v-select
              dark
              class="pa-0 ma-0"
              single-line
              style="width: 100px; max-width: 120px"
              :items="langs"
              item-text="name"
              item-value="id"
              v-model="$i18n.locale"
            ></v-select>
          </v-row> -->
        </v-col>
      </v-row>
    </v-footer>
    <link rel="stylesheet" type="text/css" :href="fontsUrl" />
  </v-app>
</template>

<script>
import axios from "axios";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "../src/assets/main.css";

export default {
  name: "Main",
  data: () => ({
    links: [],

    dialog: false,
    langs: [],
    currentFooter: {
      linkId: "",
      linkName: "",
      linkUrl: "",
      linkContent: "",
    },
    imagenPrincipal: "",
    backgroundPosition: "",
    backgroundSize: "",
    backgroundRepeat: "",
  }),
  created() {
    document.title =  this.$appConfig.pageTitle ?? this.$campaignName;
    window.addEventListener('resize', this.getImagen);
    this.getImagen();
  },
    destroyed() {
        window.removeEventListener('resize', this.getImagen);
    },
  beforeMount() {
    // Update favicon
    const favicon = document.getElementById("favicon");
    favicon.href = this.$appConfig.favicon
      ? this.$appConfig.favicon
      : "<%= BASE_URL %>galantaicon.ico";

    this.links = this.$appConfig.links;
    this.langs = this.$appConfig.langs ?? this.langs;
    this.$vuetify.theme.themes = this.$appConfig.themes;
    this.$vuetify.theme.dark = this.$appConfig.dark;
  },
  mounted() {
    const signalrRedeem = new HubConnectionBuilder()
      .withUrl(process.env.VUE_APP_SIGNALR_HOST, {
        headers: {
          "x-ms-signalr-userid": this.$uuid,
          "Access-Control-Allow-Origin": location.origin,
        },
      })
      .configureLogging(LogLevel.None)
      .build();

    signalrRedeem.on("codeRedeem", this.CodeRedeemSignalr);
    signalrRedeem.onclose((event) => {
      console.log("disconnected");
      if (event?.code == 1006) {
        console.log("reloading...");
        this.waitAndReload();
      }
    });

      this.getImagen();

    console.log("connecting signalr...");
    signalrRedeem
      .start()
      .then(() => {
        console.log("connection success");
        this.ready = true;
      })
      .catch(console.error);
  },

  methods: {
    waitAndReload: async function () {
      await fetch("");
      location.reload(true);
    },
    redirect(link) {
        window.open(link, '_blank');
      },
    getImagen: function() {          
     
      if(window.innerWidth < 490){
        this.imagenPrincipal = this.$appConfig?.mobileImage;   
        this.backgroundPosition =  'center 0';
        this.backgroundSize = '100%';  
        this.backgroundRepeat = 'no-repeat';
        return this.imagenPrincipal;
      }   
      
     //alert(window.innerWidth);

       if(window.innerWidth >= 768 && window.innerWidth < 1023){
        this.backgroundPosition =  'center 0';
        this.backgroundSize = '100%'; 
        this.backgroundRepeat = 'no-repeat';
        this.imagenPrincipal = this.$appConfig?.mobileImage;   
       // alert('SI');    
        return this.imagenPrincipal;
      } 

      if(window.innerWidth >= 1024 && window.innerWidth < 1400){
        this.backgroundPosition =  'center 0';
        this.backgroundSize = '100%'; 
        this.backgroundRepeat = 'no-repeat';
        this.imagenPrincipal = this.$appConfig?.tabletImage;      
        return this.imagenPrincipal;
      } 



       if( window.innerWidth > 1400){        
        this.imagenPrincipal = this.$appConfig.appBackGroundImage; 
        this.backgroundPosition =  '0 0';
        this.backgroundSize = 'cover';  
        this.backgroundRepeat = 'no-repeat';
        return this.imagenPrincipal;
      }     

       this.imagenPrincipal = this.$appConfig.appBackGroundImage;      
       return this.imagenPrincipal;  
    },

    openFooter(id) {
      const linkItem = this.links.find((item) => item.linkId === id);
      const globalizedLink = linkItem.linkUrl.replace(
        "#lang#",
        this.$i18n.locale
      );

      const globalizedName = linkItem.linkName.find(
        (_) => _.culture === this.$i18n.locale
      ).name;

      this.currentFooter.linkId = linkItem.linkId;

      this.currentFooter.linkName = globalizedName;
      this.currentFooter.linkUrl = linkItem.linkUrl;

      const linkExternal =  linkItem.linkName.find(
        (_) => _.culture === this.$i18n.locale
      ).linkUrlExternal;


      if(linkItem.urlExternal == true) {   
        this. redirect(linkExternal); 
      }
      else
      {
        this.getHtmlContent(globalizedLink);
        this.$el.scrollTop = 0;
        this.dialog = true;
     }
    },
    async getHtmlContent(url) {
      await axios
        .get(url)
        .then((response) => {
          this.currentFooter.linkContent = response.data;
        })
        .catch(function (err) {
          console.error(err);
        });
    },
    CodeRedeemSignalr(clientMessage) {
      if (clientMessage.Success == true) {
        if (
          this.$store.state.code == clientMessage.Message.Code &&
          this.$campaignKey == clientMessage.Message.CampaignKey
        ) {
          setTimeout(() => {
            this.$root.redeemConfirming = false;
            var providerWindow = window.open(
              clientMessage.Message.RedeemURL,
              "_blank"
            );
            providerWindow.focus();
          }, 3000);
        }
      } else {
        this.$root.redeemConfirmationMessage = clientMessage.Message;
        this.$router.push("redeemconfirmwarning");
      }
    },
    isDarkColor: function (color) {
      // Get background color hex value. '#' is optional.
      let hex = color.charAt(0) === "#" ? color.substring(1, 7) : color;

      // Convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }

      // By this point, it should be 6 characters
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }

      let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? false : true;
    },
  },
  computed: {
    backgroundDefault: function () {
      return {
        "background-image": "url(" + this.imagenPrincipal + " )",
        "background-position": this.backgroundPosition,
        "background-size": this.backgroundSize,
        "background-repeat": this.backgroundRepeat,
        "font-family": this.$appConfig.fontName,
        "background-attachment" : "fixed",
      };
    },
    fontsUrl: function () {
      return process.env.VUE_APP_CONFIG_FILE_PATH + "default_config/fonts.css";
    },
  },
};
</script>

<style>
html {
  overflow-y: auto;
}
.customMsgContentClass {
  font-size: large;
}
</style>
